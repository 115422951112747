// json import validation
var fakeEmail = [],
	fakeNumber = [],
	isDesktop = true;

// validator methods
var errorMsg,
	dynamicErrorMsg = function() {
		return errorMsg;
	}

$.validator.addMethod("phone", function(value, element) {
	var result = true;

	if(!/^0(2|3|4|7|8){1}[0-9]{8}$/.test(value) || !validateFakeData(value))
		result =  false;

	return result;
}, "Please enter a valid mobile number or landline (including the area code).");

$.validator.addMethod("address", function(value, element) {
	var result = true;

	if(!/^(.+),[\s]*(.+),[\s]*(\d{4})$/.test(value) || !postCode_validation($(element))) {
		result = false;
	}

	console.log(postCode_validation($(element)), /^(.+),[\s]*(.+),[\s]*(\d{4})$/.test(value));

	return result;

}, "Please select your suburb from the drop down list after typing in your postcode.");

$.validator.addMethod("postcodeonly", function(value, element) {
	var result = true;

	if(!/^(\d{4})$/.test(value) || !postCodeOnlyvalidation($(element))) {
		result = false;
	}

	return result;

}, "Please enter a valid 4 digit postcode.");

$.validator.addMethod("mobileOnly", function(value, element) {
	var result = true;

	if(!/^0(4){1}[0-9]{8}$/.test(value) || !validateFakeData(value))
			result =  false;

			return result;
}, "Please enter a valid mobile number");

$.validator.addMethod("landlineOnly", function(value, element) {
	var result = true;

	if(!/^0(2|3|7|8){1}[0-9]{8}$/.test(value) || !validateFakeData(value))
			result =  false;

			return result;
}, "Please enter a valid landline number");

// $.validator.addMethod("checkbox", function(value, element) {
// 	var result = true;
// 	if (!$(element).is(':checked')) {
// 		result = false;
// 	}
// 	return result;
// }, "Please accept terms and conditions");

$.validator.addMethod("emailExtra", function(value, element) {
	var result = true,
		emailVal = value;

	if (emailVal.indexOf('..') > -1 || emailVal.indexOf('.') === 0 || emailVal.indexOf('.@') > -1 || emailVal.indexOf('.') === -1 || /[^A-Za-z0-9_@.-]/g.test(emailVal) == true || emailVal.split('@')[1].indexOf('.') === -1 || !validateFakeData(value)) {
		result = false;
	}

	return result;
}, "Please enter email in correct format");


$.validator.addMethod("fullname", function(value, element) {
	errorMsg = 'Please enter full name';
	var result = true,
		elemval = $.trim(value),
		fullname_test = elemval.split(' '),
		patt = new RegExp(/^[a-zA-Z-' ]+$/),
		firstname = '',
		lastname = '';
	if (fullname_test.length < 2) {
		errorMsg = 'Please enter your first name and last name';
		result = false;
	} else {
		lastname = fullname_test[fullname_test.length - 1];
		for (var i = 0; i < fullname_test.length - 1; i++) {
			if (i > 0) {
				firstname += ' ';
			}
			firstname += fullname_test[i];
		}
		if (firstname.length < 2 || lastname.length < 2) {
			errorMsg = 'Name under 2 characters'
			result = false;
		} else if (firstname == lastname) {
			errorMsg = 'Firstname and lastname identical';
			result = false;
		} else if (!patt.test(elemval)) {
			errorMsg = 'Please enter only alphabets';
			result = false
		}
	}

	return result;

}, dynamicErrorMsg);


// input validation
$('input[type="tel"], input[type="number"]').keydown(function(e) {
	if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 224, 17, 91, 93]) !== -1 ||
		// Allow: Ctrl+A
		(e.keyCode == 65 && e.ctrlKey === true) ||
		(e.keyCode == 65 && e.metaKey === true) ||
		(e.keyCode >= 35 && e.keyCode <= 39)) { // Allow: home, end, left, right
		// let it happen, don't do anything
		return;
	}
	// Ensure that it is a number and stop the keypress
	if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
		e.preventDefault();
	}
});

$('.phone').keyup(function(e) {
	var txtVal = $(this).val();
	if (txtVal.length > 10)
		$(this).val(txtVal.slice(0,10))
});


var validateMyAjaxInputs = function (step, stepid,animateMobOnly) {

	var rules = {},
		messages = {},
    result = false,
		tooltip_options = {};

	$(stepid).find('input:not([type="hidden"]):not([type="radio"]):not([type="submit"]):not([type="checkbox"]), select.required').each(function(i, value) {
		var validationformat = $(this).attr('data-format') !== undefined ? $(this).attr('data-format') : 'require',
			validationmessage = $(this).attr('data-msg') !== undefined ? $(this).attr('data-msg') : '',
			field_name = $(this).attr('name');

		rules[field_name] = {
			required: true
		}

		if (validationformat !== undefined && validationformat !== 'require')
			rules[field_name][validationformat] = true;

		if (validationmessage !== '')
			messages[field_name] = validationmessage;

		tooltip_options[field_name] = {
			placement: 'bottom'
		}

	});

	var validator = $(stepid).parents('form').length > 0 ? $(stepid).parents('form') : $(stepid);

	validator.validate({
			rules: rules,
			messages: messages,
			tooltip_options: tooltip_options,
			invalidHandler: function(form, validator) {
				desktopChecker();
				if(!animateMobOnly){
					$('html, body').animate({
						scrollTop: $(validator.errorList[0].element).offset().top - 50
					}, 100);
				}else{
					if( !isDesktop){
						$('html, body').animate({
							scrollTop: $(validator.errorList[0].element).offset().top - 50
						}, 100);
					}
				}

				

			}
		});
		var result = false;

	if ($(stepid).find('input[type="email"]').length > 0 && $(stepid).find('input[type="email"]').val() !== '') {
		$(stepid).find('input[type="email"]').each(function(i, value) {
			console.log($(this));
			$(this).rules("add", {
				emailExtra: true
			});
		});
	}

	if ($(stepid).find('input[data-format="address"]').length > 0) {
		$(stepid).find('input[data-format="address"]').each(function(i, value) {

			$(this).rules('add', {
				address: true
			});

		});
	}

	if ($(stepid).find('input[data-format="postcodeonly"]').length > 0) {
		$(stepid).find('input[data-format="postcodeonly"]').each(function(i, value) {

			$(this).rules('add', {
				postcodeonly: true
			});

		});
	}


	if (validator.valid())
		result = true
	else
		result = false


	// radio validation
	var count = 1;
	if ($(stepid).find('input[data-format="radio"]').length > 0) {
		$('.radio-error').remove();
		$(stepid).find('input[data-format="radio"]').each(function(i, value) {
			var elem = $(this);
			var validationmessage = '<small class="radio-error">This field is required.</small>';
			var getname = elem.attr('name');
			if ($(stepid).find('input[name="' + getname + '"]:checked').length < 1) {
				if (count % 2 !== 0 && count < 2) {
					elem.parents('.flex-field-wrap').find('.flex-option-label').append(validationmessage);
					if($(this).hasClass('option-radio')) {
						elem.parents('.option-group').append(validationmessage);
					}
				}
				result = false;
				count++;
			}
		});
	}


	return result;

}

// Postcodes
var choices = [];
var arr_postcode = [];
var suburbFull;
var suburValue;
var postValue;
$(function() {
	$.getJSON(geodataJson, function(data) {
		$.each(data, function(name, value) {
			value[2] = value[2].length < 4 ? '0' + value[2] : value[2];
			arr_postcode.push("" + value.join(', '));
		});
		$('input[data-format="address"]').autoComplete({
			minChars: 1,
			source: function(term, suggest) {
				term = term.toLowerCase();
				var choices = arr_postcode;

				var matches = [];
				for (var i = 0; i < choices.length; i++) {
					if (~choices[i].toLowerCase().indexOf(term))
						matches.push(choices[i]);
				}
				suggest(matches);
			},
			onSelect: function(e, term, item) {
				$(this).focus();
				$('.autocomplete-suggestions').hide();
			},
			cache: false
		});
	});
});

var postCode_validation = function (element) {
	var suburbExist = false;
	var postCodeVal = $(element).val().trim();
	console.log(postCodeVal);
	for (var i = 0; i < arr_postcode.length; i++) {
		if (arr_postcode[i] === postCodeVal) {
			suburbExist = true;
			break;
		}
	}
	return suburbExist;
}

// prefill hidden
var prefill_hidden_address = function(elem){

	var address_val = elem.val().split(',');

	if(address_val.length > 2) {
		elem.siblings('.hidden_suburb').val(address_val[0].trim().toLowerCase());
		elem.siblings('.hidden_state').val(address_val[1].trim().toLowerCase());
		elem.siblings('.hidden_postcode').val(address_val[2].trim());
	}

};

$('input[data-format="address"]').each(function(){
	$(this).blur(function(){
		prefill_hidden_address($(this));
	});
});

$(document).on('change', '.cover-section input', function(){
	// console.log($(this).val());

	if ($('form.lead-form .styled.hosp:checked').length === 0) {
		if ($('form.lead-form .styled.extra:checked').length === 0) {
			$('#errorModal').modal('show')
			$('html,body').animate({
				scrollTop: $('#lead-form').offset().top
			}, 250);

			setTimeout(function() {
				$('.cover-section input:eq(0)').attr('checked', 'checked');
			}, 100);

			return false;

		}
	}
});

// fake data list
$(function(){
	$.getJSON("https://asset.alternativemedia.com.au/fake.json", function (data) {
	  fakeNumber = data.fakeNumber;
	  fakeEmail = data.fakeEmail
	});
});

// fake data validation
var validateFakeData = function (value) {
    var result = true;
    for(var i=0;i<fakeEmail.length;i++) {
		if(fakeEmail[i] === value) {
	        result = false;
	        break;
		}
    }
    for(var i=0;i<fakeNumber.length;i++) {
		if(fakeNumber[i] === value) {
	        result = false;
	        break;
		}
    }

	if( (value == '0400000000' || value == '0499999999') && (location.href.indexOf('dev.') !== -1 || location.href.indexOf('staging') !== -1 || location.href.indexOf('.loc') !== -1) )
		result = true;

    return result
}


// postcode only related scripts
function limitKeypress(event, value, maxLength) {
	if (value != undefined && value.toString().length >= maxLength) {
			event.preventDefault();
	}
}

function postCodeOnlyvalidation(element) {
	var suburbExist = false,
			postCodeVal = element.val().trim(),
			suburbExist = false,
			postcodeSelected = ['Happy Valley, NT, 4825','Ernabella (PUKATJA), WA, 0872','BMungindi, QLD, 2406','Mayfield, ACT, 2540','Burra, ACT, 2620','Mildura, NSW, 3500','Castle Donnington, NSW, 3585','Bulga, NSW, 3586','Biggara, NSW, 3707','Cannon Creek, NSW, 4380'];

		if(!/^(\d{4})$/.test(postCodeVal))
			return false;

		for (var i in arr_postcode) {
			if(checkFromArr(element, arr_postcode[i], postCodeVal)) {
				suburbExist = true;
				break;
			}
		}

		for (var a in postcodeSelected) {
			if(checkFromArr(element, postcodeSelected[a], postCodeVal)) {
				suburbExist = true;
				break;
			}
		}

		return suburbExist;
	
}

function checkFromArr(elem, arrval, elemval) {
	var result = false;
	if(arrval.indexOf(elemval) !== -1) {
		elem.siblings('.hidden_address').val(arrval);
		prefill_hidden_address(elem.siblings('.hidden_address'));
		result = true;
	}
	return result;
}
function desktopChecker(){
	var windowWidth = $(window).width();
	if(windowWidth > 1024){
		isDesktop = true;
	}else{
		isDesktop = false;
	}
}

$(function(){
	var postcodeOnlyField = $('.postcode-num');

	postcodeOnlyField.on('keypress', function(event){
			limitKeypress(event,$(this).val(),4)
	});

	postcodeOnlyField.blur(function(event){
			var el = $(this),
					currentVal = el.val();

			if ( currentVal =='' ) 
				el.val('4 Digit Postcode');
			
	});

	postcodeOnlyField.focus(function(event){
			if ( $(this).val() == '4 Digit Postcode') $(this).val('');
	});

	postcodeOnlyField.keyup(function(){
		if($(this).val().length == 4)
			postCodeOnlyvalidation($(this))
	});

	postcodeOnlyField.attr('name', 'postcode');
	if(postcodeOnlyField.length > 0)
		postcodeOnlyField.siblings('.hidden_postcode').remove();

	$('<input type="hidden" name="address" class="hidden_address form-control">').insertAfter('.postcode-num');


});
